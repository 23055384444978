import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';


export interface ICaptureOptions {
  tags?: {
    [tagName: string]: string | number | boolean;
  }
  extra?: {
    [tagName: string]: string | number | boolean;
  }
}

const KEY = 'LogRocketInitialized';

export function initLogging() {
  if (typeof window !== 'undefined') {
    const init = window[KEY];

    if (!init && process.env.LOG_ROCKET_ID) {
      window[KEY] = true;
      LogRocket.init(process.env.LOG_ROCKET_ID);
      setupLogRocketReact(LogRocket);
    }
  }
}

export function logMessage(message: string, opts?: ICaptureOptions, level: 'warn' | 'info' = 'warn') {
  if (typeof window !== 'undefined' && process.env.LOG_ROCKET_ID) {
    LogRocket.captureMessage(message, opts);
  } else {
    console[level](message, opts);
  }
}

export function logError(error: Error, opts?: ICaptureOptions) {
  if (typeof window !== 'undefined' && process.env.LOG_ROCKET_ID) {
    LogRocket.captureException(error, opts);
  } else {
    console.error(error, opts);
  }
}
