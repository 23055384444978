import {MessagePortSink} from './MessagePortSink';


export class RemoteWritableStream {
  public readablePort: MessagePort;
  public writable: WritableStream;

  constructor(WritableStream) {
    const channel = new MessageChannel();
    this.readablePort = channel.port1;
    this.writable = new WritableStream(
      new MessagePortSink(channel.port2)
    );
  }
}
