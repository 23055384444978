import {FileContainerFragmentData} from '@app-features/file-tree/types';
import {
  Application,
  File as ApiFile,
  FileContainer,
  FileNodeMembership,
  FileRole,
  Folder,
  Maybe,
  OrganizationMembership,
  OrganizationRole,
  Scalars,
  Team,
  User,
  UserNotification,
  ValidationError,
} from '@app-lib/apollo/apiTypes';
import moment, {Moment} from 'moment';
import {Connection} from './apiTypeGenerics';


export type INode =
  | { __typename: string, id: Scalars["ID"] }
  | { __typename: string, id: Scalars["ID"], mutationId?: string }
  | { __typename: string, mutationId: string }

export interface GeoJsonPoint {
  type: 'Point',
  /** Longitude, Latitude */
  coordinates: [number, number],
}

export type ClientMutationResponse = {
  clientMutationId?: Maybe<Scalars['UUID']>,
  code: Scalars['String'],
  success: Scalars['Boolean'],
  message: Scalars['String'],
  validationErrors: Array<ValidationError> | null,
};

export interface BatchFailureData<Model> {
  models: Array<Model>
  message?: string | null
}

export type UserData = Pick<User,
  | "__typename"
  | "id"
  | "name"
  | "email"
  | "avatar">

export type ApplicationData = Pick<Application,
  | "__typename"
  | "id"
  | "name">

export type TeamData = Pick<Team,
  | "__typename"
  | "id"
  | "name"
  | "type"
  | "avatars">

export interface TeamDataWithMembers extends TeamData {
  members: Connection<UserData>,
}

export type OrganizationRoleData = Pick<OrganizationRole,
  | "__typename"
  | "id"
  | "name">

export interface OrgMemberData extends Pick<OrganizationMembership,
  | "__typename"
  | "id"> {
  user: UserData,
  role: OrganizationRoleData
}

// export interface OrgMemberDataWithTeams extends OrgMemberData {
//   teams: Connection<TeamData>,
// }

export interface FileNodeMembershipData extends Pick<FileNodeMembership,
  | "__typename"
  | "id"> {
  fileNode:
    | Pick<Folder, "__typename" | "id" | "name">
    | Pick<FileContainer, "__typename" | "id" | "name">
    | Pick<ApiFile, "__typename" | "id" | "name" | "url">
  fileRole: Pick<FileRole,
    | "__typename"
    | "id"
    | "name">
}

export interface FileNodeActorData extends Pick<FileNodeMembership,
  | "__typename"
  | "id"> {
  actor:
    | UserData
    | TeamData
  fileRole: Pick<FileRole,
    | "__typename"
    | "id"
    | "name">
}

export enum UploadState {
  Pending = 'PENDING',
  Requesting = 'REQUESTING',
  Uploading = 'UPLOADING',
  Success = 'SUCCESS',
  Error = 'ERROR',
}

export interface FilePlaceholder {
  __typename: 'FilePlaceholder',
  mutationId: Maybe<Scalars['UUID']>,
  name: string,
  notes?: string,
  fileContainerId: Scalars['ID'],
  fileContainer: FileContainerFragmentData
  containerName: string,
  parentType: 'FileContainer',
  parentId: Scalars['ID'],
  path: string
  pathIds: Array<Scalars['ID']>,
  renameFile: boolean,
}

interface FileUploadBase {
  __typename: 'FileUpload',
  mutationId: Maybe<Scalars['UUID']>,
  jobId?: number;
  name: string,
  file?: File,
  dataUri?: string | null,
  parentId: Scalars['ID'],
  path: string
  pathIds: Array<Scalars['ID']>,

  status: UploadState,
  /**
   * Hide complete items from the upload manager.
   * This is a stopgap to allow the user to clear clutter from the Upload Manager.
   * Complete uploads may be hidden from the manager while still displaying in a folder until synced with the API.
   */
  hide?: boolean,
  percent: number | null,
  /** Unix timestamp */
  startTime: number,
  error?: string | null,
  retryable?: boolean,
}

export interface FolderUpload extends FileUploadBase {
  parentType: 'Folder',
}

export interface FileContainerUpload extends FileUploadBase {
  parentType: 'FileContainer',
  fileContainerId: Scalars['ID'],
}

export type FileUpload =
  | FolderUpload
  | FileContainerUpload

export type AvailableFileUpload = FileUpload & {
  file: File;
}

export type LocalUserNotification = Pick<UserNotification,
  | "__typename"
  | "id"
  | "time"
  | "message"
  | "read"> & {
  title: string,
};

export function isoDateToMomentDate(value: string | null | undefined): Moment | null {
  return value ? moment(value, 'YYYY-MM-DD', true) : null;
}

export function momentDateToISODate(value: Moment | null | undefined): string | null {
  if (!value) {
    return null;
  } else {
    return value.format('YYYY-MM-DD');
  }
}
