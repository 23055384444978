import {Maybe} from '@app-lib/apollo/apiTypes';
import {Connection} from '@app-lib/apollo/apiTypeGenerics';
import {INode} from '@app-lib/apollo/localTypes';


export const EMPTY_ARRAY = [];

export const EMPTY_CONNECTION = {
  edges: [],
  pageInfo: {
    __typename: 'PageInfo',
    hasNextPage: false,
    hasPreviousPage: false,
  },
  total: 0,
};

export function getListId(item: INode): string {
  // prefer mutation id for file types to support upload system behavior.
  // prefer id otherwise.
  switch (item.__typename) {
    case 'File':
    case 'FileSubmission':
    case 'FilePlaceholder':
    case 'FileUpload':
      // @ts-ignore -interface guarantees at least 1 of these always exist on a valid input
      return item.mutationId || item.id;
    default:
      // TODO: document remaining types that use mutationId.
      // @ts-ignore -interface guarantees at least 1 of these always exist on a valid input
      return item.id || item.mutationId;
  }
}

/**
 * Returns true only if the arrays are the same identity, are both falsey, or are both empty.
 */
export function arrayIs<T>(a1: Maybe<Array<T>>, a2: Maybe<Array<T>>): boolean {
  if ((!a1 && !a2) || a1 === a2) {
    return true;
  } else if (!a1 || !a2) {
    return false;
  } else if (a1.length === 0 && a2.length === 0) {
    return true;
  }

  return false;
}

export function setEquals<T>(s1: Set<T>, s2: Set<T>): boolean {
  if (s1.size !== s2.size) return false;
  for (const a of s1) {
    if (!s2.has(a)) return false;
  }
  return true;
}

/**
 * Filter an input that may be an Array or a Relay Connection.
 * Return only the Node values in the result, not Edge values in the case of a Connection.
 */
export function filterArrayOrConnection<T, R extends T = T>(
  list: Array<T> | Connection<T>,
  fn: (item: T) => boolean,
): Array<R> {
  if ("edges" in list) {
    const result: Array<R> = [];
    for (const it of list.edges) {
      if (fn(it.node)) {
        result.push(it.node as R);
      }
    }
    return result;
  } else {
    return list.filter(it => fn(it)) as Array<R>;
  }
}

export function mergeMaps<K, V>(maps: Array<Map<K, V>>, depth: number = 0) {
  const result = new Map();

  for (const map of maps) {
    for (const [key, value] of map) {
      if (depth > 0 && value instanceof Map) {
        const existing = result.get(key);
        if (existing) {
          result.set(key, mergeMaps([existing, value], depth - 1));
        }
      } else {
        result.set(key, value);
      }
    }
  }

  return result;
}
