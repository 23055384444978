export interface ITheme {
  Light: "LIGHT",
  Dark: "DARK",
  fromPrefCookie: (cookie: any) => "LIGHT" | "DARK",
  toPrefCookie: (value?: "LIGHT" | "DARK") => ({th: string}),
  Values:
    | "LIGHT"
    | "DARK"
}

// This needs to be lazily initialized for pass to Enum to work.
// @ts-ignore
export const Theme: ITheme = {
  Light: 'LIGHT' as "LIGHT",
  Dark: 'DARK' as "DARK",
};

const ENCODED_THEME = {
  [Theme.Light]: 'lt',
  [Theme.Dark]: 'dk',
};

Theme.fromPrefCookie = ({th = ENCODED_THEME[Theme.Light]} = {}) => {
  return th === ENCODED_THEME[Theme.Light] ? Theme.Light : Theme.Dark;
};

Theme.toPrefCookie = (value = Theme.Light) => {
  return {th: ENCODED_THEME[value]};
};
