import {createAction} from 'redux-starter-kit';


export interface RecentlyAccessedPayload {
  id: string;
}

export type RecentAccessActions =
  | ReturnType<typeof accessedTenant>
  | ReturnType<typeof hasAccessedTenant>
  | ReturnType<typeof accessedProject>
  | ReturnType<typeof hasAccessedProject>
  | ReturnType<typeof accessedJob>
  | ReturnType<typeof hasAccessedJob>
  | ReturnType<typeof accessedFolder>
  | ReturnType<typeof hasAccessedFolder>
  | ReturnType<typeof accessedFile>
  | ReturnType<typeof hasAccessedFile>

export const accessedTenant = createAction<RecentlyAccessedPayload, "recent-access/accessedTenant">("recent-access/accessedTenant");
export const hasAccessedTenant = createAction<RecentlyAccessedPayload, "recent-access/hasAccessedTenant">("recent-access/hasAccessedTenant");
export const accessedProject = createAction<RecentlyAccessedPayload, "recent-access/accessedProject">("recent-access/accessedProject");
export const hasAccessedProject = createAction<RecentlyAccessedPayload, "recent-access/hasAccessedProject">("recent-access/hasAccessedProject");
export const accessedJob = createAction<RecentlyAccessedPayload, "recent-access/accessedJob">("recent-access/accessedJob");
export const hasAccessedJob = createAction<RecentlyAccessedPayload, "recent-access/hasAccessedJob">("recent-access/hasAccessedJob");
export const accessedFolder = createAction<RecentlyAccessedPayload, "recent-access/accessedFolder">("recent-access/accessedFolder");
export const hasAccessedFolder = createAction<RecentlyAccessedPayload, "recent-access/hasAccessedFolder">("recent-access/hasAccessedFolder");
export const accessedFile = createAction<RecentlyAccessedPayload, "recent-access/accessedFile">("recent-access/accessedFile");
export const hasAccessedFile = createAction<RecentlyAccessedPayload, "recent-access/hasAccessedFile">("recent-access/hasAccessedFile");
