import {
  FileUpload,
  UploadState,
} from '@app-lib/apollo/localTypes';
import {FileUploadQueueService} from '@app-system/upload-manager/services/FileUploadQueueService';
import {MonitorStats} from '@forks/swmq';
import {
  isEqual,
  omit,
  omitBy,
  sortBy,
} from 'lodash';
import {createReducer} from 'redux-starter-kit';
import {
  clearComplete,
  hideComplete,
  hideUploadManager,
  loadImageData,
  removeFile,
  removeFiles,
  resetUploads,
  retryBatchUploads,
  retryUpload,
  showUploadManager,
  startBatchUpload,
  startUpload,
  uploadError,
  UploadManagerStartUploadPayload,
  uploadProgress,
  uploadValidationError,
} from './actions';


export interface UploadMap {
  [key: string]: FileUpload;
}

export interface UploadManagerState {
  files: UploadMap;
  uploads: Array<FileUpload>;
  visible: Array<FileUpload>;
  showManager: boolean;
  stats: MonitorStats;
}

export const initialState: UploadManagerState = {
  files: {},
  uploads: [],
  visible: [],
  showManager: false,
  stats: {
    pending: 0,
    inProgress: 0,
    errors: 0,
    complete: 0,
    total: 0,
  },
};

export const legacyReducer = createReducer<UploadManagerState>(initialState, {
  [showUploadManager.type]: handleShowUploadManager,
  [hideUploadManager.type]: handleHideUploadManager,
  [startUpload.type]: handleStartUpload,
  [startBatchUpload.type]: handleStartBatchUpload,
  [retryUpload.type]: handleRetryUpload,
  [retryBatchUploads.type]: handleRetryBatchUploads,
  [loadImageData.type]: handleLoadImageData,
  [uploadProgress.type]: handleUploadProgress,
  [uploadError.type]: handleUploadError,
  [uploadValidationError.type]: handleUploadValidationError,
  [removeFile.type]: handleRemoveFile,
  [removeFiles.type]: handleRemoveFiles,
  [hideComplete.type]: handleHideComplete,
  [clearComplete.type]: handleClearComplete,
  [resetUploads.type]: handleResetUploads,
});

export const swmqReducer = createReducer<UploadManagerState>(initialState, {
  [showUploadManager.type]: handleShowUploadManager,
  [hideUploadManager.type]: handleHideUploadManager,
});

export const reducer = FileUploadQueueService.isSupported() ? swmqReducer : legacyReducer;

function handleShowUploadManager(draft: UploadManagerState, action: ReturnType<typeof showUploadManager>): void {
  draft.showManager = true;
}

function handleHideUploadManager(draft: UploadManagerState, action: ReturnType<typeof hideUploadManager>): void {
  draft.showManager = false;
}

function buildNewUpload(data: UploadManagerStartUploadPayload): FileUpload {
  if (data.parentType === 'FileContainer') {
    return {
      __typename: 'FileUpload',
      name: data.name,
      mutationId: data.mutationId,
      file: data.file,
      parentId: data.parentId,
      parentType: 'FileContainer',
      fileContainerId: data.fileContainerId,
      path: data.path,
      pathIds: data.pathIds,

      status: UploadState.Requesting,
      percent: null,
      startTime: Date.now(),
    };
  } else {
    return {
      __typename: 'FileUpload',
      name: data.name,
      mutationId: data.mutationId,
      file: data.file,
      parentId: data.parentId,
      parentType: 'Folder',
      path: data.path,
      pathIds: data.pathIds,

      status: UploadState.Requesting,
      percent: null,
      startTime: Date.now(),
    };
  }
}

function handleStartUpload(draft: UploadManagerState, {payload}: ReturnType<typeof startUpload>): void {
  draft.files[payload.mutationId] = buildNewUpload(payload);

  sortUploads(draft);
}

function handleUploadValidationError(draft: UploadManagerState,
  {payload}: ReturnType<typeof uploadValidationError>): void {
  draft.files[payload.mutationId] = {
    ...buildNewUpload(payload),
    error: payload.error,
    status: UploadState.Error,
    retryable: false,
  };

  sortUploads(draft);
}

function handleStartBatchUpload(draft: UploadManagerState, {payload}: ReturnType<typeof startBatchUpload>): void {
  payload.files.forEach(data => {
    draft.files[data.mutationId] = buildNewUpload(data);
  });

  sortUploads(draft);
}

function handleRetryUpload(draft: UploadManagerState, {payload}: ReturnType<typeof retryUpload>): void {
  draft.files[payload.mutationId].status = UploadState.Requesting;
  draft.files[payload.mutationId].percent = null;
  draft.files[payload.mutationId].startTime = Date.now();

  sortUploads(draft);
}

function handleRetryBatchUploads(draft: UploadManagerState, {payload}: ReturnType<typeof retryBatchUploads>): void {
  payload.mutationIds.forEach(mutationId => {
    draft.files[mutationId].status = UploadState.Requesting;
    draft.files[mutationId].percent = null;
    draft.files[mutationId].startTime = Date.now();
  });

  sortUploads(draft);
}

function handleLoadImageData(draft: UploadManagerState, {payload}: ReturnType<typeof loadImageData>): void {
  const currentItem = draft.files[payload.mutationId];
  if (!currentItem) {
    return;
  }

  draft.files[payload.mutationId].dataUri = payload.dataUri;

  sortUploads(draft);
}

function handleUploadProgress(draft: UploadManagerState, {payload}: ReturnType<typeof uploadProgress>): void {
  const currentItem = draft.files[payload.mutationId];
  if (!currentItem) {
    return;
  }

  currentItem.status = payload.status;
  currentItem.percent = payload.percent;

  sortUploads(draft);
}

function handleUploadError(draft: UploadManagerState, {payload}: ReturnType<typeof uploadError>): void {
  const currentItem = draft.files[payload.mutationId];
  if (!currentItem) {
    return;
  }

  currentItem.status = UploadState.Error;
  currentItem.percent = 100;
  currentItem.error = payload.error;
  currentItem.retryable = payload.retryable;

  sortUploads(draft);
}

function handleRemoveFile(draft: UploadManagerState, {payload}: ReturnType<typeof removeFile>): void {
  delete draft.files[payload.mutationId];
  sortUploads(draft);
}

function handleRemoveFiles(state: UploadManagerState, {payload}: ReturnType<typeof removeFiles>): UploadManagerState {
  return sortUploads({
    ...state,
    files: omit(state.files, payload.uploads.map(it => it.mutationId)),
  });
}

function handleHideComplete(draft: UploadManagerState, {payload}: ReturnType<typeof hideComplete>): void {
  const item = draft.files[payload.mutationId];
  if (item.status === UploadState.Success) {
    draft.files[payload.mutationId].hide = true;
    sortUploads(draft);
  }
}

function handleClearComplete(state: UploadManagerState, action: ReturnType<typeof clearComplete>): UploadManagerState {
  const nextFiles = omitBy(state.files, (value, key) =>
    value.status === UploadState.Error && !value.retryable
  );
  const keys = Object.keys(nextFiles);
  for (const id of keys) {
    const value = nextFiles[id];
    if (value.status === UploadState.Success) {
      value.hide = true;
    }
  }
  return sortUploads({
    ...state,
    files: nextFiles,
  });
}

function handleResetUploads(state: UploadManagerState, action: ReturnType<typeof resetUploads>): UploadManagerState {
  return initialState;
}

// only exposed for test mock reuse
export function sortUploads(state: UploadManagerState): UploadManagerState {
  state.uploads = sortBy(
    Object.values(state.files),
    ['startTime']
  );
  state.visible = [];
  const stats: MonitorStats = {
    pending: 0,
    inProgress: 0,
    errors: 0,
    complete: 0,
    total: state.uploads.length,
  };
  for (const upload of state.uploads) {
    if (!upload.hide) {
      state.visible.push(upload);
    }
    switch (upload.status) {
      case UploadState.Pending:
        stats.pending++;
        break;
      case UploadState.Requesting:
      case UploadState.Uploading:
        stats.inProgress++;
        break;
      case UploadState.Error:
        stats.errors++;
        break;
      case UploadState.Success:
        stats.complete++;
        break;
    }
  }
  if (!isEqual(stats, state.stats)) {
    state.stats = stats;
  }

  return state;
}
