import {AppServices} from '@app-lib/services';


export class AbstractAppService {
  protected readonly isServer: boolean;

  constructor(protected readonly services: AppServices) {
    this.isServer = AbstractAppService.isServer();
  }

  static isServer() {
    return typeof window === 'undefined';
  }
}
