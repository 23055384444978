// Inspired by https://github.com/jimmywarting/native-file-system-adapter improvements over StreamSaver.js for downliading files.
import {RemoteWritableStream} from './RemoteWritableStream';


const isSafari = typeof window === 'undefined'
  ? false
  // @ts-ignore
  : (/constructor/i.test(window.HTMLElement) || window.safari || window.WebKitPoint);


export class FileHandle {
  public readonly kind: 'file';

  constructor(public name: string) {
    this.kind = 'file';
  }

  async createWritable(opts?: any): Promise<WritableStream<any>> {
    const sw = await navigator.serviceWorker.getRegistration();
    const link = document.createElement('a');
    const ts = new window.TransformStream();
    const sink = ts.writable;

    link.download = this.name;

    if (isSafari || !sw) {
      let chunks: Array<Blob> = [];
      ts.readable.pipeTo(new window.WritableStream({
        write(chunk) {
          chunks.push(new Blob([chunk]));
        },
        close() {
          const blob = new Blob(chunks, {type: 'application/octet-stream; charset=utf-8'});
          chunks = [];
          link.href = URL.createObjectURL(blob);
          link.click();
          setTimeout(() => URL.revokeObjectURL(link.href), 10000);
        },
      }));
    } else {
      const {writable, readablePort} = new RemoteWritableStream(WritableStream);
      // Make filename RFC5987 compatible
      const fileName = encodeURIComponent(this.name)
        .replace(/['()]/g, escape)
        .replace(/\*/g, '%2A');
      const headers = {
        'content-disposition': "attachment; filename*=UTF-8''" + fileName,
        'content-type': 'application/octet-stream; charset=utf-8',
        // 'content-length': unknown
      };

      const keepAlive = setTimeout(() => sw.active?.postMessage(0), 10000);

      ts.readable
        .pipeThrough(new window.TransformStream({
          transform(chunk, ctrl) {
            if (chunk instanceof Uint8Array) return ctrl.enqueue(chunk);
            // @ts-ignore - body should never be null here
            const reader = new Response(chunk).body.getReader();
            const pump = (_?) => reader
              .read()
              .then(e => e.done ? 0 : pump(ctrl.enqueue(e.value)));
            return pump();
          },
        }))
        .pipeTo(writable)
        .finally(() => {
          clearInterval(keepAlive);
        });

      // Transfer the stream to service worker
      sw.active?.postMessage({
        url: sw.scope + encodeURIComponent(this.name),
        headers,
        readablePort,
      }, [readablePort]);

      // Trigger the download with a hidden iframe
      const iframe = document.createElement('iframe');
      iframe.hidden = true;
      iframe.src = sw.scope + encodeURIComponent(this.name);
      document.body.appendChild(iframe);
    }

    return sink;
  }
}
