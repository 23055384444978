import {saveFiles} from '@app-features/file-tree/services/file-downloads';
import {AppServices} from '@app-lib/services';
import {
  DBTables,
  JobState,
  queryJobs,
  QueryJobsOptions,
  Queue,
} from '@forks/swmq';
import produce from 'immer';
import {AbstractAppService} from '../../AbstractAppService';
import {
  FileUploadJob,
  FileUploadJobData,
  FileUploadJobResponse,
} from './FileUploadQueueWorker';
import {FileUploadQueueName} from './state';


export class FileUploadQueueService extends AbstractAppService {
  readonly queue: Queue<FileUploadJobData, FileUploadJobResponse>;
  protected listening: boolean;

  constructor(services: AppServices) {
    super(services);

    this.queue = new Queue(FileUploadQueueName);
  }

  isSupported() {
    return !this.isServer
      && "indexedDB" in window
      && "serviceWorker" in navigator;
  }

  static isSupported() {
    return !AbstractAppService.isServer()
      && "indexedDB" in window
      && "serviceWorker" in navigator;
  }

  async startSync(): Promise<boolean> {
    try {
      const reg = await navigator.serviceWorker.ready;
      const sw = reg.active;
      sw?.postMessage({
        type: `${FileUploadQueueName}:StartSync`,
      });
      return !!sw;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async hideCompleted() {
    const db = await this.queue.open();
    const tx = db.transaction(DBTables.queue, 'readwrite');
    let cursor = await tx.store.openCursor();
    const items: Array<Promise<any>> = [];

    while (cursor) {
      const job = cursor.value;
      if (job?.status === JobState.Complete && !job.data.fileData.hide) {
        cursor.update(produce(job, (draft) => {
          draft.data.fileData.hide = true;
        }));
      }

      cursor = await cursor.continue();
    }

    await Promise.all([
      ...items,
      tx.done,
    ]);

    this.queue.postAction({
      type: 'mutate',
    });
  }

  async downloadFiles(opts?: QueryJobsOptions) {
    const db = await this.queue.open();
    const jobs = await queryJobs<FileUploadJob>(db, opts);
    const files = jobs.map(job => job.data.fileData.file);
    if (files.length) {
      await saveFiles(files, `uploads-${Date.now()}.zip`);
    }
  }

  async initOnClient() {
    this.services.serviceWorkerService.onSWRegistered(async ({wb, reg}) => {
      console.info('FileUploadQueueService.registered', {wb, reg});

      this.startSync();

      if (!this.listening) {
        this.listening = true;
        window.addEventListener('online', (event) => {
          this.startSync();
        });
      }

      if ('sync' in reg) {
        const tags = await reg.sync.getTags();
        if (!tags.includes(`${FileUploadQueueName}:StartSync`)) {
          try {
            await reg.sync.register(`${FileUploadQueueName}:StartSync`);
          } catch (e) {
          }
        }
      }
    });

    await this.queue.open();
  }
}
