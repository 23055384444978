import {getUniqueName} from '@app-features/file-tree/utils/fileUtils';
import {FileHandle} from '@app-lib/files/FileHandle';
import {LocalFile} from '@app-system/camera/types';
import {createWriter} from '@forks/streamsaver';
import {isUndefined} from 'lodash';


export type FileDownload =
  | RemoteFileDownload
  | LocalFileDownload

export interface RemoteFileDownload {
  type: 'RemoteFileDownload';
  payload: RemoteFileDownloadPayload;
}

export interface RemoteFileDownloadPayload {
  name: string;
  path?: string;
  url: string;
}

export interface LocalFileDownload {
  type: 'LocalFileDownload';
  payload: LocalFileDownloadPayload;
}

export interface LocalFileDownloadPayload {
  file: File | LocalFile;
}

export interface ZipDirectory {
  type: 'ZipDirectory';
  name: string;
  directory: true;
}

// Based on https://github.com/kennethjiang/js-file-download/blob/master/file-download.js
export function downloadBlob(data, filename, mime?, bom?) {
  const blobData = (!isUndefined(bom)) ? [bom, data] : [data];
  const blob = new Blob(blobData, {type: mime || 'application/octet-stream'});
  if (!isUndefined(navigator.msSaveBlob)) {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    navigator.msSaveBlob(blob, filename);
  } else {
    const blobURL = URL.createObjectURL(blob);
    downloadUrl(blobURL, filename);
  }
}

export function downloadUrl(url, filename) {
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = url;
  tempLink.setAttribute('download', filename);

  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  if (isUndefined(tempLink.download)) {
    tempLink.setAttribute('target', '_blank');
  }

  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
  URL.revokeObjectURL(url);
}

export type SaveFileTransformEvent =
  | (RemoteFileDownload & { response: Response })
  | LocalFileDownload

export interface SaveFilesAdvancedOptions {
  filename?: string;
  transform?: (event: SaveFileTransformEvent) => Promise<ReadableStream>;
}

export async function downloadFiles(files: Array<RemoteFileDownloadPayload>, filename = 'archive.zip') {
  return saveFilesAdvanced(files.map(payload => ({
    type: 'RemoteFileDownload',
    payload,
  })), {
    filename,
  });
}

export function saveFiles(files: Array<File>, filename = 'archive.zip') {
  return saveFilesAdvanced(files.map(file => ({
    type: 'LocalFileDownload',
    payload: {file},
  })), {
    filename,
  });
}

// Based on https://github.com/jimmywarting/StreamSaver.js/blob/master/examples/saving-multiple-files.html
export async function saveFilesAdvanced(files: Array<FileDownload>, opts: SaveFilesAdvancedOptions = {}) {
  const filename = opts.filename || 'archive.zip';
  const filesRemaining = [
    ...files,
  ];
  const baseNames = new Map<string, number>();
  const names = new Set<string>();

  const readableZipStream = createWriter({
    async pull(ctrl) {
      if (filesRemaining.length === 0) {
        ctrl.close();
        return;
      }

      const next = filesRemaining.pop()!;
      let fileName;
      let fileStream;
      let lastModified = Date.now();
      switch (next.type) {
        case 'LocalFileDownload': {
          const {file} = next.payload;
          if (file.lastModified) {
            lastModified = file.lastModified;
          }
          fileName = getUniqueName(file.name, names, baseNames);
          if (opts.transform) {
            fileStream = await opts.transform(next);
          } else {
            fileStream = file.stream();
          }

          break;
        }
        case 'RemoteFileDownload': {
          const {name, path, url} = next.payload;
          const fullPath = joinPath(path, name);
          fileName = getUniqueName(fullPath, names, baseNames);
          const response = await fetch(url);
          if (opts.transform) {
            fileStream = await opts.transform({...next, response});
          } else {
            fileStream = response.body;
          }

          break;
        }
      }

      const stream = () => fileStream;
      ctrl.enqueue({name: trimSlash(fileName), lastModified, stream});
    },
  });

  await zipStream(readableZipStream, filename);
}

function joinPath(path: string | null | undefined, name: string): string {
  if (!path) {
    return name;
  }

  const tokens = path
    .split('/')
    .filter(token => !!token);
  tokens.push(name);

  return tokens.join('/');
}

function trimSlash(name: string): string {
  if (name.startsWith('/')) {
    name = name.substr(1);
  }
  if (name.endsWith('/')) {
    name = name.substr(0, name.length - 1);
  }
  return name;
}

async function zipStream(readableZipStream, filename) {
  const fileStream = await new FileHandle(filename).createWritable();

  // more optimized
  if (readableZipStream.pipeTo) {
    await readableZipStream.pipeTo(fileStream);
    return;
  }

  // less optimized
  const writeStream = fileStream.getWriter();
  const reader = readableZipStream.getReader();
  const pump = () => reader.read()
    .then(res => res.done ? writeStream.close() : writeStream.write(res.value).then(pump));

  await pump();
}
