import {FileUpload} from '@app-lib/apollo/localTypes';
import {RootAppState} from '@app-lib/redux/reducers';
import {UploadManagerState} from './reducer';


export function getUploadManager(rootState: RootAppState): UploadManagerState {
  return rootState.uploadManager;
}

export function getUploadById(key: string, uploadManager: UploadManagerState): FileUpload | undefined {
  const upload = uploadManager.files[key];
  if (upload && !upload.hide) {
    return upload;
  }
}
