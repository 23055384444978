import {makeVar} from '@apollo/client';
import {DateFormatInput} from '@app-lib/apollo/apiTypes';
import {setDateOptions} from '@app-system/preferences/redux/actions';
import {getDateFormatOptions} from '@app-system/preferences/redux/state';
import moment from 'moment-timezone';
import {
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';


export const dateFormatVar = makeVar<DateFormatInput | null>(null);
export const dateTimeFormatVar = makeVar<DateFormatInput | null>(null);

function getDefaultDateFormat() {
  return moment.localeData().longDateFormat('L');
}

function getDefaultDateTimeFormat() {
  const localeData = moment.localeData();
  return `${localeData.longDateFormat('L')} ${localeData.longDateFormat('LT')}`;
}

export function useDateFormat(): DateFormatInput {
  const format = useSelector(getDateFormatOptions);
  updateOnClient(format);

  return useMemo(() => ({
    formatString: format.dateFormat || getDefaultDateFormat(),
    timezone: format.timezone || moment.tz.guess(),
  }), [format]);
}

export function useDateTimeFormat(): DateFormatInput {
  const format = useSelector(getDateFormatOptions);
  updateOnClient(format);

  return useMemo(() => ({
    formatString: format.dateTimeFormat || getDefaultDateTimeFormat(),
    timezone: format.timezone || moment.tz.guess(),
  }), [format]);
}

function updateOnClient(format) {
  const dispatch = useDispatch();

  if (typeof window !== 'undefined') {
    const firstRender = useRef(true);
    useEffect(() => {
      // For now, auto-update when a different timezone is detected for travelling users.
      // If we enable user-set TZ, we will need an additional property to prevent this overwrite.
      if (!format.timezone || (!format.lockTimezone && format.timezone !== moment.tz.guess())) {
        dispatch(setDateOptions({
          dateFormat: format.dateFormat || getDefaultDateFormat(),
          dateTimeFormat: format.dateTimeFormat || getDefaultDateTimeFormat(),
          timezone: format.timezone || moment.tz.guess(),
        }));
        firstRender.current = false;
      }
    }, [dispatch, format]);
  }
}
