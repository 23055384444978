import {
  OptionsObject,
  SnackbarKey,
} from 'notistack';
import {createAction} from 'redux-starter-kit';


export interface EnqueueNotificationPayload {
  message: string
  options?: OptionsObject
}

export interface CloseNotificationPayload {
  key: SnackbarKey
}

export type NotificationsActions =
  | ReturnType<typeof enqueueNotification>
  | ReturnType<typeof closeNotification>
  | ReturnType<typeof closeAllNotifications>

export const enqueueNotification = createAction<EnqueueNotificationPayload, "notifications/enqueue">("notifications/enqueue");
export const closeNotification = createAction<CloseNotificationPayload, "notifications/close">("notifications/close");
export const closeAllNotifications = createAction<void, "notifications/closeAll">("notifications/closeAll");
