/* eslint-disable max-len */
import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';
import React from 'react';


export function ProjectIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      width="1024"
      height="1024"
      viewBox="0 0 1024 1024"
    >
      <path d="M984 164.833v605.492c0 26.509-21.49 48-48 48l-384.064-0.012v89.839l88.064 0.029v81.686h-256v-81.686l87.996-0.029v-89.839l-383.996 0.012c-26.509 0-48-21.49-48-48v-605.492h944zM552 638.615c-26.509 0-48 21.944-48 49.012s21.49 49.012 48 49.012c26.509 0 48-21.944 48-49.012s-21.49-49.012-48-49.012zM680 638.615c-26.509 0-48 21.944-48 49.012s21.49 49.012 48 49.012c26.509 0 48-21.944 48-49.012s-21.49-49.012-48-49.012zM808 638.615c-26.509 0-48 21.944-48 49.012s21.49 49.012 48 49.012c26.509 0 48-21.944 48-49.012s-21.49-49.012-48-49.012zM511.504 491.073h-160l-80 138.867c0 0 183.256 81.523 240-138.867zM856 507.917h-176v98.024h176v-98.024zM207.504 360.374c-98.25 143.523 16.613 239.131 23.665 244.794l0.335 0.266 80-130.698-104-114.361zM856 377.218h-216v98.024h216v-98.024zM231.772 327.545l-0.268 0.154 112 122.53h168c-61.621-244.244-271.203-127.548-279.732-122.684zM856 246.52h-264v98.024h264v-98.024zM1024 34.134v89.855h-1024v-89.855h1024z" />
    </SvgIcon>
  );
}
