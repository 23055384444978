import {isNil} from 'lodash';
import {createReducer} from 'redux-starter-kit';
import {Theme} from '../enums/Theme';
import {
  receivePreferencesUpdate,
  setCameraOptions,
  setDateOptions,
  setTheme,
  setTimezone,
  toggleTheme,
} from './actions';


export interface DateFormatOptions {
  dateFormat: string | undefined;
  dateTimeFormat: string | undefined;
  timezone: string | undefined;
  /** Uset has manually picked a timezone. */
  lockTimezone: boolean | undefined;
}

export interface CameraResolution {
  width: number;
  height: number;
}

export const Resolutions: Array<CameraResolution> = [
  {width: 1024, height: 576},
  {width: 1280, height: 720},
  {width: 1920, height: 1080},
  {width: 2560, height: 1440},
  {width: 3840, height: 2160},
  {width: 4096, height: 2160},
];

export interface UserCameraOptions {
  useCameraAccept: boolean;
  cameraResolution: CameraResolution;
}

export type ThemeName = "LIGHT" | "DARK"

export interface PreferencesState extends DateFormatOptions, UserCameraOptions {
  theme: ThemeName;
}

export const initialState: PreferencesState = {
  useCameraAccept: true,
  cameraResolution: Resolutions[3],
  theme: Theme.Light,
  dateFormat: undefined,
  dateTimeFormat: undefined,
  timezone: undefined,
  lockTimezone: false,
};

export const reducer = createReducer(initialState, {
  [setCameraOptions.type]: handleSetCameraOptions,
  [setDateOptions.type]: handleSetDateOptions,
  [setTimezone.type]: handleSetTimezone,
  [setTheme.type]: handleSetTheme,
  [toggleTheme.type]: handleToggleTheme,
  [receivePreferencesUpdate.type]: handleReceivePreferencesUpdate,
});

function handleSetCameraOptions(draft, {payload}: ReturnType<typeof setCameraOptions>) {
  if (!isNil(payload.useCameraAccept)) {
    draft.useCameraAccept = payload.useCameraAccept;
  }
  if (!isNil(payload.cameraResolution)) {
    draft.cameraResolution = payload.cameraResolution;
  }

  return draft;
}

function handleSetDateOptions(draft, {payload}: ReturnType<typeof setDateOptions>) {
  draft.dateFormat = payload.dateFormat;
  draft.dateTimeFormat = payload.dateTimeFormat;
  draft.timezone = payload.timezone;
  draft.lockTimezone = payload.lockTimezone;

  return draft;
}

function handleSetTimezone(draft, {payload}: ReturnType<typeof setTimezone>) {
  if (!isNil(payload.timezone)) {
    draft.timezone = payload.timezone;
  }
  if (!isNil(payload.lockTimezone)) {
    draft.lockTimezone = payload.lockTimezone;
  }

  return draft;
}

function handleSetTheme(draft, {payload}: ReturnType<typeof setTheme>) {
  draft.theme = payload.theme;

  return draft;
}

function handleToggleTheme(draft, action: ReturnType<typeof toggleTheme>) {
  draft.theme = draft.theme === Theme.Light ? Theme.Dark : Theme.Light;

  return draft;
}

function handleReceivePreferencesUpdate(draft, {payload}: ReturnType<typeof receivePreferencesUpdate>) {
  if (!isNil(payload.useCameraAccept)) {
    draft.useCameraAccept = payload.useCameraAccept;
  }
  if (!isNil(payload.theme)) {
    draft.theme = payload.theme;
  }
  if (!isNil(payload.dateFormat)) {
    draft.dateFormat = payload.dateFormat;
  }
  if (!isNil(payload.dateTimeFormat)) {
    draft.dateTimeFormat = payload.dateTimeFormat;
  }
  if (!isNil(payload.timezone)) {
    draft.timezone = payload.timezone;
  }
  if (!isNil(payload.lockTimezone)) {
    draft.lockTimezone = payload.lockTimezone;
  }

  return draft;
}
