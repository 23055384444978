import {FailedUploadItem} from '@app-features/file-tree/lib/uploads';
import {
  FileUpload,
  UploadState,
} from '@app-lib/apollo/localTypes';
import {LocalFile} from '@app-system/camera/types';
import {createAction} from 'redux-starter-kit';


interface BaseUploadManagerStartUploadPayload {
  name: string,
  mutationId: string,
  file: File | LocalFile,
  parentId: string,
  path: string,
  pathIds: Array<string>,
}

export interface FolderUploadManagerStartUploadPayload extends BaseUploadManagerStartUploadPayload {
  parentType: "Folder",
}

export interface FileContainerUploadManagerStartUploadPayload extends BaseUploadManagerStartUploadPayload {
  parentType: "FileContainer",
  fileContainerId: string,
}

export type UploadManagerStartUploadPayload =
  | FolderUploadManagerStartUploadPayload
  | FileContainerUploadManagerStartUploadPayload

export interface UploadManagerStartBatchUploadPayload {
  files: Array<UploadManagerStartUploadPayload>,
}

export interface RetryBatchUploadsPayload {
  mutationIds: Array<string>
}

export interface LoadImageDataPayload {
  mutationId: string
  dataUri: string
}

export interface UploadProgressPayload {
  mutationId: string
  id: string
  status: UploadState
  percent: number | null
  startedAt: number
}

export interface UploadErrorPayload {
  mutationId: string
  id?: string
  error: string
  retryable: boolean
}

export interface RemoveFilePayload {
  mutationId: string;
  jobId?: number;
}

export interface UploadManagerRemoveFilesPayload {
  uploads: Array<RemoveFilePayload>;
}

export type UploadManagerActions =
  | ReturnType<typeof showUploadManager>
  | ReturnType<typeof hideUploadManager>
  | ReturnType<typeof startUpload>
  | ReturnType<typeof startBatchUpload>
  | ReturnType<typeof retryUpload>
  | ReturnType<typeof retryBatchUploads>
  | ReturnType<typeof loadImageData>
  | ReturnType<typeof uploadProgress>
  | ReturnType<typeof uploadError>
  | ReturnType<typeof uploadValidationError>
  | ReturnType<typeof removeFile>
  | ReturnType<typeof removeFiles>
  | ReturnType<typeof hideComplete>
  | ReturnType<typeof clearComplete>
  | ReturnType<typeof clearFailed>
  | ReturnType<typeof resetUploads>

export const showUploadManager = createAction<void, "upload-manager/SHOW">("upload-manager/SHOW");
export const hideUploadManager = createAction<void, "upload-manager/HIDE">("upload-manager/HIDE");
export const startUpload = createAction<UploadManagerStartUploadPayload, "upload-manager/START_UPLOAD">("upload-manager/START_UPLOAD");
export const startBatchUpload = createAction<UploadManagerStartBatchUploadPayload, "upload-manager/START_BATCH_UPLOAD">("upload-manager/START_BATCH_UPLOAD");
export const retryUpload = createAction<FileUpload, "upload-manager/RETRY_UPLOAD">("upload-manager/RETRY_UPLOAD");
export const retryBatchUploads = createAction<RetryBatchUploadsPayload, "upload-manager/RETRY_BATCH_UPLOADS">("upload-manager/RETRY_BATCH_UPLOADS");
export const loadImageData = createAction<LoadImageDataPayload, "upload-manager/LOAD_IMAGE_DATA">("upload-manager/LOAD_IMAGE_DATA");
export const uploadProgress = createAction<UploadProgressPayload, "upload-manager/PROGRESS">("upload-manager/PROGRESS");
export const uploadError = createAction<UploadErrorPayload, "upload-manager/ERROR">("upload-manager/ERROR");
export const uploadValidationError = createAction<FailedUploadItem, "upload-manager/VALIDATION_ERROR">("upload-manager/VALIDATION_ERROR");
export const removeFile = createAction<RemoveFilePayload, "upload-manager/REMOVE_FILE">("upload-manager/REMOVE_FILE");
export const removeFiles = createAction<UploadManagerRemoveFilesPayload, "upload-manager/REMOVE_FILES">("upload-manager/REMOVE_FILES");
export const hideComplete = createAction<FileUpload, "upload-manager/HIDE_COMPLETE">("upload-manager/HIDE_COMPLETE");
export const clearComplete = createAction<void, "upload-manager/CLEAR_COMPLETE">("upload-manager/CLEAR_COMPLETE");
export const clearFailed = createAction<void, "upload-manager/CLEAR_FAILED">("upload-manager/CLEAR_FAILED");
export const resetUploads = createAction<void, "upload-manager/RESET">("upload-manager/RESET");
