import {QueryJobsOptions} from '@forks/swmq';
import {createAction} from 'redux-starter-kit';


export interface SaveFilesFromUploadQueuePayload {
  options?: QueryJobsOptions;
}

export type UploadQueueActions =
  | ReturnType<typeof startUploadQueue>
  | ReturnType<typeof pauseUploadQueue>
  | ReturnType<typeof resumeUploadQueue>
  | ReturnType<typeof saveFilesFromUploadQueue>

export const startUploadQueue = createAction<void, "upload-queue/START">("upload-queue/START");
export const pauseUploadQueue = createAction<void, "upload-queue/PAUSE">("upload-queue/PAUSE");
export const resumeUploadQueue = createAction<void, "upload-queue/RESUME">("upload-queue/RESUME");
export const saveFilesFromUploadQueue = createAction<SaveFilesFromUploadQueuePayload, "upload-queue/SAVE_FILES">("upload-queue/SAVE_FILES");
