import fileExtension from 'file-extension';


export function getBaseFileName(name: string): string {
  const ext = fileExtension(name);
  const length = name.length;

  if (ext) {
    if (ext.length === length || ext.length + 1 === length) {
      // file extension is file name
      return name;
    } else {
      return name.substring(0, length - ext.length - 1);
    }

  } else {
    return name;
  }
}

/**
 * Append the file extension of filename to target.
 * @param {string} source The string to get the file extension from.
 * @param {string} target The string to append to.
 * @return {string}
 */
export function appendFileExtension(source: string, target: string): string {
  const ext = fileExtension(source);
  const baseFileName = getBaseFileName(source);

  if (baseFileName === source) {
    return target;
  } else {
    return `${target}.${ext}`;
  }
}
