import {getBaseFileName} from '@app-lib/files/nameUtils';
import {LocalFile} from '@app-system/camera/types';
import fileExtension from 'file-extension';


export function getFileThumbnail(
  file: Blob | File | LocalFile,
): Promise<string | null> {
  if (file.type.includes('image/')) {
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();

        reader.onload = () => resolve(reader.result as string);

        reader.readAsDataURL(file);
      } catch (e) {
        reject(e);
      }
    });
  } else {
    return Promise.resolve(null);
  }
}

export function getUniqueName(name: string, names: Set<string>, baseNames: Map<string, number>): string {
  const ext = fileExtension(name);
  const baseFileName = getBaseFileName(name);
  const originalName = name;
  let numeral = baseNames.get(name) || 1;
  if (!names.has(name)) {
    names.add(name);
    baseNames.set(name, numeral);
    return name;
  }
  while (true) {
    if (!names.has(name)) {
      names.add(name);
      return name;
    } else {
      numeral++;
      baseNames.set(originalName, numeral);
      if (ext) {
        name = `${baseFileName} (${numeral}).${ext}`;
      } else {
        name = `${baseFileName} (${numeral})`;
      }
    }
  }
}
