import {OrganizationFragmentData} from '@app-features/organizations/types';
import {createAction} from 'redux-starter-kit';


export interface SetTenantPayload {
  tenant: OrganizationFragmentData;
}

export type TenantActions =
  | ReturnType<typeof setTenant>
  | ReturnType<typeof loadingTenant>
  | ReturnType<typeof loadedTenant>

export const setTenant = createAction<SetTenantPayload, "tenant/setTenant">("tenant/setTenant");
export const loadingTenant = createAction<void, "tenant/loadingTenant">("tenant/loadingTenant");
export const loadedTenant = createAction<void, "tenant/loadedTenant">("tenant/loadedTenant");
