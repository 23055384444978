import {
  DBTables,
  QueueIndexes,
} from './Connection';
import {JobState} from './Job';


export type QueryJobsOptions =
  | QueryJobsById
  | QueryJobsByStatus

interface QueryJobsById {
  property: QueueIndexes.id;
  ids: Array<number>;
}

interface QueryJobsByStatus {
  property: QueueIndexes.status;
  status: JobState;
}

export async function queryJobs<T>(db, opts?: QueryJobsOptions): Promise<Array<T>> {
  const tx = db.transaction(DBTables.queue);
  const items: Array<T> = [];

  let source;
  let cursor;
  if (opts) {
    switch (opts.property) {
      case QueueIndexes.id: {
        const ids = new Set(opts.ids);
        cursor = await tx.store.openCursor();

        while (cursor) {
          const job = cursor.value;
          if (ids.has(job.id)) {
            items.push(cursor.value);
          }
          cursor = await cursor.continue();
        }
        break;
      }
      case QueueIndexes.status:
        source = tx.store.index(QueueIndexes.status);
        cursor = await source.openCursor(opts.status);
        break;
    }
  } else {
    // @ts-ignore
    if (tx.store.getAll) {
      return tx.store.getAll();
    } else {
      cursor = await tx.store.openCursor();
    }
  }

  while (cursor) {
    items.push(cursor.value);
    cursor = await cursor.continue();
  }

  return items;
}

export async function deleteJobs<T>(db, filter: (job: T) => boolean): Promise<void> {
  const tx = db.transaction(DBTables.queue, 'readwrite');

  let cursor = await tx.store.openCursor();
  const items: Array<Promise<any>> = [];

  while (cursor) {
    if (filter(cursor.value)) {
      items.push(cursor.delete());
    }
    cursor = await cursor.continue();
  }

  await Promise.all([
    ...items,
    tx.done,
  ]);
}
