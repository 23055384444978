import {createAction} from 'redux-starter-kit';
import {
  CameraResolution,
  DateFormatOptions,
  PreferencesState,
} from './reducer';


export interface SetCameraOptionsPayload {
  useCameraAccept?: boolean;
  cameraResolution?: CameraResolution;
}

export interface SetThemePayload {
  theme: PreferencesState["theme"];
}

export interface SetTimezonePayload {
  timezone?: PreferencesState["timezone"];
  lockTimezone?: PreferencesState["lockTimezone"];
}

export type SetDateOptionsPayload = Partial<DateFormatOptions>

export type ReceivePreferencesUpdatePayload = Partial<PreferencesState>

export type PreferencesActions =
  | ReturnType<typeof setCameraOptions>
  | ReturnType<typeof setTheme>
  | ReturnType<typeof toggleTheme>
  | ReturnType<typeof setDateOptions>
  | ReturnType<typeof setTimezone>
  | ReturnType<typeof receivePreferencesUpdate>

export const setCameraOptions = createAction<SetCameraOptionsPayload, "preferences/setCameraOptions">("preferences/setCameraOptions");
export const setTheme = createAction<SetThemePayload, "preferences/setTheme">("preferences/setTheme");
export const toggleTheme = createAction<void, "preferences/toggleTheme">("preferences/toggleTheme");
export const setDateOptions = createAction<SetDateOptionsPayload, "preferences/setDateOptions">("preferences/setDateOptions");
export const setTimezone = createAction<SetTimezonePayload, "preferences/setTimezone">("preferences/setTimezone");
export const receivePreferencesUpdate = createAction<ReceivePreferencesUpdatePayload, "preferences/receivePreferencesUpdate">("preferences/receivePreferencesUpdate");
