import {isUndefined} from 'lodash';


let hidden;
let visibilityChange;

export function getPageVisibility() {
  if (!isUndefined(window)) {
    if (!isUndefined(document.hidden)) {
      hidden = 'hidden';
      visibilityChange = 'visibilitychange';
      // @ts-ignore
    } else if (!isUndefined(document.msHidden)) {
      hidden = 'msHidden';
      visibilityChange = 'msvisibilitychange';
      // @ts-ignore
    } else if (!isUndefined(document.webkitHidden)) {
      hidden = 'webkitHidden';
      visibilityChange = 'webkitvisibilitychange';
    }
  }

  return {
    hidden,
    visibilityChange,
  };
}
